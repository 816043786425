import CenteredCell from '@asaprint/asap/components/tables/CenteredCell.js';
import FormatDate from '@engined/client/components/FormatDate.js';
import { DATETIME_FORMAT } from '@engined/client/locales.js';
import { SxProps } from '@mui/material';
import React from 'react';

interface Props {
  cell: { value: string };
  sx?: SxProps;
}

const DateTimeCell: React.FunctionComponent<Props> = ({ cell: { value }, sx }) => {
  return <CenteredCell sx={sx}>{value && <FormatDate date={value} format={DATETIME_FORMAT} />}</CenteredCell>;
};

DateTimeCell.displayName = 'DateTimeCell';

export default React.memo(DateTimeCell);
