import EditTimeEntryDialog from '@asaprint/asap/components/dialogs/EditTimeEntryDialog.js';
import { TOOLTIP_EDIT } from '@asaprint/asap/locales/client.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useDialog from '@engined/client/hooks/useDialog.js';
import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

interface OwnProps {
  timeEntryId: string;
  refetchQueries: string[];
}

type Props = OwnProps;

const TimeEntryEditButton: React.FunctionComponent<Props> = ({ timeEntryId, refetchQueries }) => {
  const { t } = useLocale();
  const {
    open: editTimeEntryDialogOpen,
    onOpen: editTimeEntryDialogOnOpen,
    onClose: editTimeEntryDialogOnClose,
  } = useDialog<boolean>(false);

  return (
    <>
      <Tooltip title={t(TOOLTIP_EDIT)} placement="top" onClick={editTimeEntryDialogOnOpen}>
        <IconButton size="small">
          <Edit />
        </IconButton>
      </Tooltip>

      {editTimeEntryDialogOpen && (
        <EditTimeEntryDialog
          onClose={editTimeEntryDialogOnClose}
          open={editTimeEntryDialogOpen}
          timeEntryId={timeEntryId}
          refetchQueries={refetchQueries}
        />
      )}
    </>
  );
};

TimeEntryEditButton.displayName = 'TimeEntryEditButton';

export default React.memo<Props>(TimeEntryEditButton);
