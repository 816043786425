import { DATETIME_FORMAT } from '@engined/client/locales.js';
import { DateFilter as SchemaDateFilter } from '@asaprint/asap/schema.client.types.js';
import InputFilter from '@engined/client/components/table/InputFilter.js';
import { FilterComponent } from '@asaprint/asap/components/tables/interfaces.js';
import React from 'react';

const DateTimeFilter: FilterComponent<any, SchemaDateFilter> = (props) => {
  return <InputFilter {...props} />;
};

DateTimeFilter.displayName = 'DateTimeFilter';

DateTimeFilter.filterToGraphQLVariable = (value: string, language: string) => {
  return {
    format: DATETIME_FORMAT[language],
    like: `%${value}%`,
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

export default React.memo(DateTimeFilter);
