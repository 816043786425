export enum TimeEntryType {
  PAID = 0,
  NON_PAID = 1,
}

export enum TimeEntryPosition {
  Creative_Director,
  Strategic_Planner,
  Account_Manager,
  Art_Director,
  Copywriter,
  Programmer,
  Social_Media_Specialist,
  Online_Performance_Manager,
  Graphic_Designer,
  Coder,
  Motion_Designer,
  Producer,
  Account_Executive,
  DTP_Operator,
}

export const TIME_ENTRY_POSITION_NAMES = {
  [TimeEntryPosition.Graphic_Designer]: 'Graphic Designer',
  [TimeEntryPosition.DTP_Operator]: 'DTP Operator',
  [TimeEntryPosition.Account_Manager]: 'Account Manager',
  [TimeEntryPosition.Creative_Director]: 'Creative Director',
  [TimeEntryPosition.Strategic_Planner]: 'Strategic Planner',
  [TimeEntryPosition.Art_Director]: 'Art Director',
  [TimeEntryPosition.Copywriter]: 'Copywriter',
  [TimeEntryPosition.Programmer]: 'Programmer',
  [TimeEntryPosition.Social_Media_Specialist]: 'Social Media Specialist',
  [TimeEntryPosition.Online_Performance_Manager]: 'Online Performance Manager',
  [TimeEntryPosition.Coder]: 'Coder',
  [TimeEntryPosition.Motion_Designer]: 'Motion Designer',
  [TimeEntryPosition.Producer]: 'Producer',
  [TimeEntryPosition.Account_Executive]: 'Account Executive',
};
